"use client"

import { useState, useRef } from 'react'
import { Plus, Calendar } from 'lucide-react'
import { format } from "date-fns"
import { DateRange } from "react-day-picker"

import { Button } from "./components/ui/Button"
import { Input } from "./components/ui/Input"
import { Label } from "./components/ui/Label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./components/ui/Select"
import { Textarea } from "./components/ui/Textarea"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./components/ui/Popover"
import { Calendar as CalendarComponent } from "./components/ui/Calendar"
import { Checkbox } from "./components/ui/Checkbox"
import { cn } from "./lib/utils"
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./components/ui/Sheet"

interface Job {
  id: number;
  production: string;
  role: string;
  startDate: Date | undefined;
  endDate: Date | undefined;
  approximateDates: boolean;
  location: string;
  budget: string;
  budgetRate: string;
  description: string;
  status: 'Filled' | 'Hiring';
  name?: string;
  email?: string;
  phoneNumber?: string;
}

export default function Component({ onJobCreated }: { onJobCreated: (job: Job) => void }) {
  const [open, setOpen] = useState(false)
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const datePickerTriggerRef = useRef<HTMLButtonElement>(null)
  const [newJob, setNewJob] = useState<Job>({
    id: 0,
    production: '',
    role: '',
    startDate: undefined,
    endDate: undefined,
    approximateDates: false,
    location: '',
    budget: '',
    budgetRate: 'per day',
    description: '',
    status: 'Hiring',
  })
  const [date, setDate] = useState<DateRange | undefined>({
    from: undefined,
    to: undefined,
  })
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [showErrors, setShowErrors] = useState(false)

  const handleNewJobChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewJob({ ...newJob, [e.target.name]: e.target.value })
    if (showErrors) {
      validateField(e.target.name, e.target.value)
    }
  }

  const validateField = (field: string, value: string | undefined) => {
    if (!value || value.trim() === '') {
      setErrors(prev => ({ ...prev, [field]: 'This field is required' }))
    } else {
      setErrors(prev => {
        const newErrors = { ...prev }
        delete newErrors[field]
        return newErrors
      })
    }
  }

  const validateAllFields = () => {
    const fieldsToValidate = ['production', 'role', 'location', 'budget', 'description', 'status']
    if (newJob.status === 'Filled') {
      fieldsToValidate.push('name', 'email', 'phoneNumber')
    }
    const newErrors: Record<string, string> = {}

    fieldsToValidate.forEach(field => {
      if (!newJob[field as keyof typeof newJob]) {
        newErrors[field] = 'This field is required'
      }
    })

    if (!date?.from || !date?.to) {
      newErrors.dates = 'Please select a date range'
    }

    setErrors(newErrors)
    setShowErrors(true)
    return Object.keys(newErrors).length === 0
  }

  const handleNewJobSubmit = () => {
    if (validateAllFields()) {
      const newJobWithId = { ...newJob, id: Date.now(), startDate: date?.from, endDate: date?.to }
      onJobCreated(newJobWithId)
      setOpen(false)
      resetForm()
    }
  }

  const resetForm = () => {
    setNewJob({
      id: 0,
      production: '',
      role: '',
      startDate: undefined,
      endDate: undefined,
      approximateDates: false,
      location: '',
      budget: '',
      budgetRate: 'per day',
      description: '',
      status: 'Hiring',
    })
    setDate({ from: undefined, to: undefined })
    setErrors({})
    setShowErrors(false)
  }

  const handleDateSelect = (selectedDate: DateRange | undefined) => {
    setDate(selectedDate)
    if (selectedDate?.from && selectedDate?.to) {
      setIsDatePickerOpen(false)
      if (showErrors) {
        validateField('dates', 'valid')
      }
    }
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button variant="outline" className="gap-2">
          <Plus className="h-4 w-4" />
          Add Crew
        </Button>
      </SheetTrigger>
      <SheetContent side="bottom" className="h-[90vh] overflow-y-auto">
        <SheetHeader>
          <SheetTitle>Add Crew</SheetTitle>
          <SheetDescription>Fill in the details for the new crew member.</SheetDescription>
        </SheetHeader>
        <div className="grid gap-4 py-4">
          {showErrors && Object.keys(errors).length > 0 && (
            <div className="text-red-500 text-sm">
              Please complete all required fields.
            </div>
          )}
          <div className="grid gap-2">
            <Label htmlFor="production">Production</Label>
            <Select
              name="production"
              onValueChange={(value) => {
                setNewJob({ ...newJob, production: value })
                if (showErrors) validateField('production', value)
              }}
            >
              <SelectTrigger className={cn(errors.production && showErrors && "border-red-500")}>
                <SelectValue placeholder="Select production" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Survivor Antartica">Survivor Antartica</SelectItem>
                <SelectItem value="The Voice">The Voice</SelectItem>
                <SelectItem value="Master Chef">Master Chef</SelectItem>
              </SelectContent>
            </Select>
            {errors.production && showErrors && (
              <span className="text-red-500 text-sm">{errors.production}</span>
            )}
          </div>
          <div className="grid gap-2">
            <Label htmlFor="role">Role</Label>
            <Input
              id="role"
              name="role"
              value={newJob.role}
              onChange={handleNewJobChange}
              className={cn(errors.role && showErrors && "border-red-500")}
              placeholder="Enter role"
            />
            {errors.role && showErrors && (
              <span className="text-red-500 text-sm">{errors.role}</span>
            )}
          </div>
          <div className="grid gap-2">
            <Label htmlFor="dates">Dates</Label>
            <Popover open={isDatePickerOpen} onOpenChange={setIsDatePickerOpen}>
              <PopoverTrigger asChild>
                <Button
                  id="date"
                  ref={datePickerTriggerRef}
                  variant="outline"
                  className={cn(
                    "w-full justify-start text-left font-normal",
                    !date && "text-muted-foreground",
                    errors.dates && showErrors && "border-red-500"
                  )}
                >
                  <Calendar className="mr-2 h-4 w-4" />
                  {date?.from ? (
                    date.to ? (
                      <>
                        {format(date.from, "LLL dd, y")} -{" "}
                        {format(date.to, "LLL dd, y")}
                      </>
                    ) : (
                      format(date.from, "LLL dd, y")
                    )
                  ) : (
                    <span>Pick a date range</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <CalendarComponent
                  initialFocus
                  mode="range"
                  defaultMonth={date?.from}
                  selected={date}
                  onSelect={handleDateSelect}
                  numberOfMonths={2}
                />
              </PopoverContent>
            </Popover>
            {errors.dates && showErrors && (
              <span className="text-red-500 text-sm">{errors.dates}</span>
            )}
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="approximateDates"
              checked={newJob.approximateDates}
              onCheckedChange={(checked) => setNewJob({ ...newJob, approximateDates: checked as boolean })}
            />
            <label
              htmlFor="approximateDates"
              className="text-sm font-medium leading-none"
            >
              Dates are approximate
            </label>
          </div>
          <div className="grid gap-2">
            <Label htmlFor="location">Location</Label>
            <Select
              name="location"
              onValueChange={(value) => {
                setNewJob({ ...newJob, location: value })
                if (showErrors) validateField('location', value)
              }}
            >
              <SelectTrigger className={cn(errors.location && showErrors && "border-red-500")}>
                <SelectValue placeholder="Select location" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Sydney">Sydney</SelectItem>
                <SelectItem value="Melbourne">Melbourne</SelectItem>
                <SelectItem value="Brisbane">Brisbane</SelectItem>
              </SelectContent>
            </Select>
            {errors.location && showErrors && (
              <span className="text-red-500 text-sm">{errors.location}</span>
            )}
          </div>
          <div className="grid gap-2">
            <Label htmlFor="budget">Budgeted Rate</Label>
            <div className="flex gap-2">
              <Input
                id="budget"
                name="budget"
                placeholder="Enter budget"
                value={newJob.budget}
                onChange={handleNewJobChange}
                className={cn("flex-1", errors.budget && showErrors && "border-red-500")}
              />
              <Select
                name="budgetRate"
                value={newJob.budgetRate}
                onValueChange={(value) => setNewJob({ ...newJob, budgetRate: value as 'per day' | 'per week' })}
              >
                <SelectTrigger className="w-[130px]">
                  <SelectValue placeholder="Select rate" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="per day">per day</SelectItem>
                  <SelectItem value="per week">per week</SelectItem>
                </SelectContent>
              </Select>
            </div>
            {errors.budget && showErrors && (
              <span className="text-red-500 text-sm">{errors.budget}</span>
            )}
          </div>
          <div className="grid gap-2">
            <Label htmlFor="description">Job Description</Label>
            <Textarea
              id="description"
              name="description"
              placeholder="Enter job description"
              value={newJob.description}
              onChange={handleNewJobChange}
              className={cn(errors.description && showErrors && "border-red-500")}
            />
            {errors.description && showErrors && (
              <span className="text-red-500 text-sm">{errors.description}</span>
            )}
          </div>
          <div className="grid gap-2">
            <Label htmlFor="status">Status</Label>
            <Select
              name="status"
              value={newJob.status}
              onValueChange={(value: 'Filled' | 'Hiring') => {
                setNewJob({ ...newJob, status: value })
                if (showErrors) validateField('status', value)
              }}
            >
              <SelectTrigger className={cn(errors.status && showErrors && "border-red-500")}>
                <SelectValue placeholder="Select status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Hiring">Hiring</SelectItem>
                <SelectItem value="Filled">Filled</SelectItem>
              </SelectContent>
            </Select>
            {errors.status && showErrors && (
              <span className="text-red-500 text-sm">{errors.status}</span>
            )}
          </div>
          {newJob.status === 'Filled' && (
            <>
              <div className="grid gap-2">
                <Label htmlFor="name">Name</Label>
                <Input
                  id="name"
                  name="name"
                  value={newJob.name || ''}
                  onChange={handleNewJobChange}
                  className={cn(errors.name && showErrors && "border-red-500")}
                  placeholder="Enter name"
                />
                {errors.name && showErrors && (
                  <span className="text-red-500 text-sm">{errors.name}</span>
                )}
              </div>
              <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={newJob.email || ''}
                  onChange={handleNewJobChange}
                  className={cn(errors.email && showErrors && "border-red-500")}
                  placeholder="Enter email"
                />
                {errors.email && showErrors && (
                  <span className="text-red-500 text-sm">{errors.email}</span>
                )}
              </div>
              <div className="grid gap-2">
                <Label htmlFor="phoneNumber">Phone Number</Label>
                <Input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="tel"
                  value={newJob.phoneNumber || ''}
                  onChange={handleNewJobChange}
                  className={cn(errors.phoneNumber && showErrors && "border-red-500")}
                  placeholder="Enter phone number"
                />
                {errors.phoneNumber && showErrors && (
                  <span className="text-red-500 text-sm">{errors.phoneNumber}</span>
                )}
              </div>
            </>
          )}
        </div>
        <div className="flex justify-end gap-2 mt-6">
          <Button
            variant="outline"
            onClick={() => {
              setOpen(false)
              resetForm()
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleNewJobSubmit}>Add Crew</Button>
        </div>
      </SheetContent>
    </Sheet>
  )
}