import CandidatesPage from "./CandidatesPage"
import CrewDetails from "./CrewDetails"
import PageHeader from "./PageHeader"

const sampleCrewMember = {
  id: 1,
  production: "Survivor Antartica",
  role: "Camera Operator",
  startDate: new Date("2024-12-01"),
  endDate: new Date("2025-02-28"),
  approximateDates: true,
  location: "Sydney",
  budget: "850",
  budgetRate: "per day",
  description: "Experienced camera operator needed for challenging outdoor shooting conditions. Must have experience with extreme weather filming and be comfortable working in cold environments. Responsibilities include operating various camera systems, maintaining equipment, and collaborating with the directing team.",
  status: "Hiring" as const,
}

const filledCrewMember = {
  id: 2,
  production: "The Voice",
  role: "Sound Mixer",
  startDate: new Date("2024-01-15"),
  endDate: new Date("2024-06-30"),
  approximateDates: false,
  location: "Melbourne",
  budget: "1200",
  budgetRate: "per day",
  description: "Lead sound mixer position for prime-time television show. Responsibilities include managing audio team, mixing live performances, and ensuring broadcast-quality sound for all segments.",
  status: "Filled" as const,
  name: "Sarah Johnson",
  email: "sarah.j@example.com",
  phoneNumber: "+61 400 123 456"
}

export default function CrewPage() {
  return (
    <>
    <PageHeader title={''}/>
    <div className="min-h-screen bg-gray-50 py-8 px-4 md:px-8">
      <h1 className="text-3xl font-bold mb-2">Crew Details</h1>
      <p className="text-muted-foreground">View and manage crew positions</p>
        {/* <CrewDetails job={sampleCrewMember} /> */}
        <CrewDetails job={filledCrewMember} />
        <CandidatesPage />
    </div>
    </>
  )
}