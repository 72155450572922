// import Image from 'next/image'
import { useState, useRef } from 'react'
import { Button } from "./components/ui/Button"
import { Card, CardContent, CardHeader, CardTitle, CardDescription, CardFooter } from "./components/ui/Card"
import { Input } from "./components/ui/Input"
import { Label } from "./components/ui/Label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./components/ui/Select"
import { CalendarIcon, Upload, X } from 'lucide-react'
import { Separator } from "./components/ui/Separator"
import { Alert, AlertDescription, AlertTitle } from "./components/ui/Alert"
import { AlertCircle } from 'lucide-react'

// This would typically come from your API or props
const jobDetails = {
  job: "Camera Operator",
  production: "Survivor Antarctica",
  startDate: "2024-11-01",
  endDate: "2025-02-28",
  location: "Antarctica",
  budget: "500",
  budgetRate: "per day",
  description: "We're seeking an experienced camera operator for our upcoming season of Survivor, set in the challenging environment of Antarctica. The ideal candidate should have experience in extreme weather conditions and be able to capture compelling footage in a fast-paced reality TV setting.",
}

interface Experience {
  productionName: string;
  startMonth: string;
  startYear: string;
  endMonth: string;
  endYear: string;
  role: string;
  showReel?: string;
}

interface FormErrors {
  name?: string;
  email?: string;
  phone?: string;
  location?: string;
  dayRate?: string;
  experiences?: {
    productionName?: string;
    startMonth?: string;
    startYear?: string;
    endMonth?: string;
    endYear?: string;
    role?: string;
    showReel?: string;
  }[];
  cv?: string;
}

const currentYear = new Date().getFullYear()
const years = Array.from({ length: 20 }, (_, i) => (currentYear - i).toString())

export default function ListingDetailPublic() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    location: '',
    dayRate: '',
  })
  const [cv, setCV] = useState<File | null>(null)
  const [experiences, setExperiences] = useState<Experience[]>([
    { productionName: '', startMonth: '', startYear: '', endMonth: '', endYear: '', role: '', showReel: '' },
    { productionName: '', startMonth: '', startYear: '', endMonth: '', endYear: '', role: '', showReel: '' },
    { productionName: '', startMonth: '', startYear: '', endMonth: '', endYear: '', role: '', showReel: '' },
  ])
  const [errors, setErrors] = useState<FormErrors>({})
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
    // Clear the error when the user starts typing
    setErrors(prev => ({ ...prev, [e.target.name]: undefined }))
  }

  const handleExperienceChange = (index: number, field: keyof Experience, value: string) => {
    const newExperiences = [...experiences]
    newExperiences[index][field] = value
    setExperiences(newExperiences)
    // Clear the error when the user starts typing
    setErrors(prev => ({
      ...prev,
      experiences: prev.experiences?.map((exp, i) =>
        i === index ? { ...exp, [field]: undefined } : exp
      )
    }))
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setCV(e.target.files[0])
      setErrors(prev => ({ ...prev, cv: undefined }))
    }
  }

  const handleDeleteCV = () => {
    setCV(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {}

    // Validate contact details
    if (!formData.name.trim()) newErrors.name = "Name is required"
    if (!formData.email.trim()) newErrors.email = "Email is required"
    else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Email is invalid"
    if (!formData.phone.trim()) newErrors.phone = "Phone number is required"
    if (!formData.location.trim()) newErrors.location = "Location is required"
    if (!formData.dayRate.trim()) newErrors.dayRate = "Day rate is required"
    else if (isNaN(Number(formData.dayRate))) newErrors.dayRate = "Day rate must be a number"

    // Validate experiences
    newErrors.experiences = experiences.map(exp => ({
      productionName: !exp.productionName.trim() ? "Production name is required" : undefined,
      startMonth: !exp.startMonth.trim() ? "Start month is required" : undefined,
      startYear: !exp.startYear ? "Start year is required" : undefined,
      endMonth: !exp.endMonth.trim() ? "End month is required" : undefined,
      endYear: !exp.endYear ? "End year is required" : undefined,
      role: !exp.role.trim() ? "Role is required" : undefined,
      showReel: exp.showReel && !/^https?:\/\//.test(exp.showReel) ? "Show reel must be a valid URL" : undefined,
    }))

    // Validate CV
    if (!cv) newErrors.cv = "CV is required"

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const isValid = validateForm()
    if (isValid) {
      // Here you would typically send the form data, experiences, and CV file to your server
      console.log('Application submitted:', formData)
      console.log('Experiences:', experiences)
      console.log('CV:', cv)
      // Reset form after submission
      setFormData({
        name: '',
        email: '',
        phone: '',
        location: '',
        dayRate: '',
      })
      setExperiences([
        { productionName: '', startMonth: '', startYear: '', endMonth: '', endYear: '', role: '', showReel: '' },
        { productionName: '', startMonth: '', startYear: '', endMonth: '', endYear: '', role: '', showReel: '' },
        { productionName: '', startMonth: '', startYear: '', endMonth: '', endYear: '', role: '', showReel: '' },
      ])
      setCV(null)
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
      setErrors({})
      alert('Application submitted successfully!')
    } else {
      // Scroll to the top of the form to show the error banner
      window.scrollTo(0, 0)
    }
  }

  const hasErrors = () => {
    return Object.keys(errors).length > 0 || errors.experiences?.some(exp => Object.values(exp).some(Boolean))
  }

  return (
    <div className="container mx-auto p-4 mt-8 space-y-8 max-w-[800px]">
      <div className="mb-8">
        {/* <Image
          src="/IMG_0334.png"
          alt="ITV Studios Australia Crewing"
          width={300}
          height={60}
          className="w-auto h-auto"
        /> */}
      </div>
      <Card>
        <CardHeader className="pb-2">
          <CardTitle>{jobDetails.job}</CardTitle>
          <CardDescription className="text-base">{jobDetails.production}</CardDescription>
        </CardHeader>
        <CardContent className="space-y-4 pt-0">
          <div className="flex items-center space-x-2">
            <span className="font-semibold">Dates:</span>
            <span>
              {new Date(jobDetails.startDate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' })} - {' '}
              {new Date(jobDetails.endDate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' })}
            </span>
          </div>
          <div>
            <strong>Location:</strong> {jobDetails.location}
          </div>
          <div>
            <strong>Budget:</strong> ${jobDetails.budget} {jobDetails.budgetRate}
          </div>
          <div>
            <strong>Job Description:</strong>
            <p className="mt-2">{jobDetails.description}</p>
          </div>
        </CardContent>
      </Card>

      <Separator className="my-8" />

      <div className="space-y-4">
        <h2 className="text-2xl font-bold">Apply for this Position</h2>
        <p className="text-muted-foreground">Please fill out the form below to apply</p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <Card>
          <CardHeader>
            <CardTitle>Contact details</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="name">Full Name</Label>
              <Input
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
                className={errors.name ? "border-red-500" : ""}
              />
              {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
            </div>
            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                className={errors.email ? "border-red-500" : ""}
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
            </div>
            <div className="space-y-2">
              <Label htmlFor="phone">Mobile Number</Label>
              <Input
                id="phone"
                name="phone"
                type="tel"
                value={formData.phone}
                onChange={handleInputChange}
                required
                className={errors.phone ? "border-red-500" : ""}
              />
              {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
            </div>
            <div className="space-y-2">
              <Label htmlFor="location">Location</Label>
              <Input
                id="location"
                name="location"
                value={formData.location}
                onChange={handleInputChange}
                required
                className={errors.location ? "border-red-500" : ""}
              />
              {errors.location && <p className="text-red-500 text-sm">{errors.location}</p>}
            </div>
            <div className="space-y-2">
              <Label htmlFor="dayRate">Day Rate</Label>
              <Input
                id="dayRate"
                name="dayRate"
                type="number"
                value={formData.dayRate}
                onChange={handleInputChange}
                required
                className={errors.dayRate ? "border-red-500" : ""}
              />
              {errors.dayRate && <p className="text-red-500 text-sm">{errors.dayRate}</p>}
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Recent Experience</CardTitle>
          </CardHeader>
          <CardContent className="space-y-6">
            {experiences.map((exp, index) => (
              <div key={index}>
                <div className="space-y-4">
                  <h4 className="font-medium">Job {index + 1}</h4>
                  <div className="space-y-2">
                    <Label htmlFor={`production-${index}`}>Production Name</Label>
                    <Input
                      id={`production-${index}`}
                      value={exp.productionName}
                      onChange={(e) => handleExperienceChange(index, 'productionName', e.target.value)}
                      required
                      className={errors.experiences?.[index]?.productionName ? "border-red-500" : ""}
                    />
                    {errors.experiences?.[index]?.productionName && <p className="text-red-500 text-sm">{errors.experiences[index].productionName}</p>}
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor={`startMonth-${index}`}>Start Month</Label>
                    <Input
                      id={`startMonth-${index}`}
                      value={exp.startMonth}
                      onChange={(e) => handleExperienceChange(index, 'startMonth', e.target.value)}
                      required
                      className={errors.experiences?.[index]?.startMonth ? "border-red-500" : ""}
                    />
                    {errors.experiences?.[index]?.startMonth && <p className="text-red-500 text-sm">{errors.experiences[index].startMonth}</p>}
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor={`startYear-${index}`}>Start Year</Label>
                    <Select
                      value={exp.startYear}
                      onValueChange={(value) => handleExperienceChange(index, 'startYear', value)}
                    >
                      <SelectTrigger className={`w-full ${errors.experiences?.[index]?.startYear ? "border-red-500" : ""}`}>
                        <SelectValue placeholder="Select Year" />
                      </SelectTrigger>
                      <SelectContent>
                        {years.map((year) => (
                          <SelectItem key={year} value={year}>
                            {year}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    {errors.experiences?.[index]?.startYear && <p className="text-red-500 text-sm">{errors.experiences[index].startYear}</p>}
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor={`endMonth-${index}`}>End Month</Label>
                    <Input
                      id={`endMonth-${index}`}
                      value={exp.endMonth}
                      onChange={(e) => handleExperienceChange(index, 'endMonth', e.target.value)}
                      required
                      className={errors.experiences?.[index]?.endMonth ? "border-red-500" : ""}
                    />
                    {errors.experiences?.[index]?.endMonth && <p className="text-red-500 text-sm">{errors.experiences[index].endMonth}</p>}
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor={`endYear-${index}`}>End Year</Label>
                    <Select
                      value={exp.endYear}
                      onValueChange={(value) => handleExperienceChange(index, 'endYear', value)}
                    >
                      <SelectTrigger className={`w-full ${errors.experiences?.[index]?.endYear ? "border-red-500" : ""}`}>
                        <SelectValue placeholder="Select Year" />
                      </SelectTrigger>
                      <SelectContent>
                        {years.map((year) => (
                          <SelectItem key={year} value={year}>
                            {year}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    {errors.experiences?.[index]?.endYear && <p className="text-red-500 text-sm">{errors.experiences[index].endYear}</p>}
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor={`role-${index}`}>Role</Label>
                    <Input
                      id={`role-${index}`}
                      value={exp.role}
                      onChange={(e) => handleExperienceChange(index, 'role', e.target.value)}
                      required
                      className={errors.experiences?.[index]?.role ? "border-red-500" : ""}
                    />
                    {errors.experiences?.[index]?.role && <p className="text-red-500 text-sm">{errors.experiences[index].role}</p>}
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor={`show-reel-${index}`}>Link to Show / Reel (Optional)</Label>
                    <Input
                      id={`show-reel-${index}`}
                      value={exp.showReel}
                      onChange={(e) => handleExperienceChange(index, 'showReel', e.target.value)}
                      placeholder="https://"
                      className={errors.experiences?.[index]?.showReel ? "border-red-500" : ""}
                    />
                    {errors.experiences?.[index]?.showReel && <p className="text-red-500 text-sm">{errors.experiences[index].showReel}</p>}
                  </div>
                </div>
                {index < experiences.length - 1 && <Separator className="my-6" />}
              </div>
            ))}
          </CardContent>
        </Card>

        <Card>
          <CardContent className="pt-6">
            <div className="space-y-2">
              <Label htmlFor="cv">Upload CV</Label>
              <div className="flex items-center space-x-2">
                <Input
                  id="cv"
                  name="cv"
                  type="file"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  accept=".pdf,.doc,.docx"
                  className="hidden"
                />
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => fileInputRef.current?.click()}
                  className={errors.cv ? "border-red-500" : ""}
                >
                  <Upload className="mr-2 h-4 w-4" />
                  Upload
                </Button>
                {cv && (
                  <div className="flex items-center bg-secondary text-secondary-foreground px-3 py-1 rounded-md">
                    <span className="mr-2">{cv.name}</span>
                    <Button
                      type="button"
                      variant="ghost"
                      size="sm"
                      onClick={handleDeleteCV}
                      className="h-auto p-0"
                    >
                      <X className="h-4 w-4" />
                      <span className="sr-only">Delete CV</span>
                    </Button>
                  </div>
                )}
              </div>
              {errors.cv && <p className="text-red-500 text-sm">{errors.cv}</p>}
            </div>
          </CardContent>
        </Card>

        {hasErrors() && (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              Please fill out all required fields before submitting.
            </AlertDescription>
          </Alert>
        )}
        <Button type="submit" className="w-full">Submit Application</Button>
      </form>
    </div>
  )
}