import React, { useState, useEffect } from 'react';
import { getCurrentUser } from '@aws-amplify/auth';

const Profile: React.FC = () => {
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    fetchUserData();
  }, []);

  async function fetchUserData() {
    try {
      const userData = await getCurrentUser();
      setUser(userData);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Profile</h1>
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <p className="mb-2"><strong>Username:</strong> {user.username}</p>
        <p className="mb-2"><strong>Email:</strong> {user.signInDetails?.loginId}</p>
        <p className="mb-2"><strong>Auth Type:</strong> {user.signInDetails?.authFlowType}</p>
        {/* Add more user attributes as needed */}
      </div>
    </div>
  );
};

export default Profile;