import React, { useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { BrowserRouter as Router, Route, Link, Routes, Navigate, useLocation } from 'react-router-dom';
import { Authenticator, useAuthenticator, useTheme } from '@aws-amplify/ui-react';

// import { Button } from "./components/ui/Button";
// import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "./components/ui/Card";
// import { NavigationMenu, NavigationMenuItem, NavigationMenuLink, NavigationMenuList } from "./components/ui/NavigationMenu";

// Pages & Nav
import Home from './Home';
import Profile from './Profile';
import Navigation from './components/Navigation';
import Listings from './Listings';
import ListingDetailPublic from './ListingDetailPublic';
import { Hub } from 'aws-amplify/utils';
import ListingDetailInternal from './ListingDetailInternal';
import CandidatesPage from './CandidatesPage';
import Productions from './Productions';

// Define types for auth events
type AuthEventData = {
  username?: string;
  attributes?: Record<string, any>;
  user?: any;
  message?: string;
};

type AuthEventType = 
  | 'signUp'
  | 'confirmSignUp'
  | 'signUp_failure'
  | 'signIn'
  | 'signOut'
  | 'customOAuthState';

interface HubPayload {
  event: AuthEventType;
  data?: AuthEventData;
  message?: string;
}

// Configure Amplify
const config = {
  "aws_project_region": "ap-southeast-2",
  "aws_cognito_region": "ap-southeast-2",
  "aws_user_pools_id": "ap-southeast-2_WueEdmEtY",
  "aws_user_pools_web_client_id": "5ng5vbcsidhesm5q0op1e5kb7j",
};
Amplify.configure(config);

// Separate auth listener function for better debugging
const authListener = ({ payload: { event, data } }: { payload: HubPayload }) => {
  console.log('Auth Event Received:', event);
  console.log('Auth Event Data:', data);
  
  switch (event) {
    case 'signUp':
      console.log('📝 Sign Up Event:', { data });
      break;
    case 'confirmSignUp':
      console.log('✅ Confirm Sign Up Event:', { data });
      break;
    case 'signUp_failure':
      console.error('❌ Sign Up Failure Event:', { data });
      break;
    case 'signIn':
      console.log('🔑 Sign In Event:', { data });
      break;
    case 'signOut':
      console.log('👋 Sign Out Event:', { data });
      break;
    case 'customOAuthState':
      console.log('🔄 Custom OAuth State Event:', { data });
      break;
    default:
      console.log('📢 Other Auth Event:', event, data);
  }
};

const AuthenticatedApp: React.FC = () => {
  const { tokens } = useTheme();
  const { authStatus, signOut } = useAuthenticator((context) => [context.authStatus]);

  const isAuthenticated = authStatus === 'authenticated';

  useEffect(() => {
    console.log('🎧 Setting up Auth Hub listener...');
    
    // Test Hub is working
    Hub.dispatch('auth', { 
      event: 'signIn',
      data: { message: 'Test dispatch' }
    });

    // Set up Hub listener
    const listener = Hub.listen('auth', authListener);

    // Log when auth status changes
    console.log('🔒 Current auth status:', authStatus);

    // Cleanup listener on component unmount
    return () => {
      console.log('🧹 Cleaning up Auth Hub listener...');
      listener();
    };
  }, [authStatus]);

  useEffect(() => {
    // Log authentication status changes
    console.log('Authentication status changed:', isAuthenticated ? 'Authenticated' : 'Not authenticated');
  }, [isAuthenticated]);

  return (
    <Router>
      <Navigation isAuthenticated={isAuthenticated} onLogout={signOut} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/example-public-listing" element={<ListingDetailPublic />} />
        <Route 
          path="/profile" 
          element={isAuthenticated ? <Profile /> : <Navigate to="/" />} 
        />
        <Route 
          path="/listings" 
          element={isAuthenticated ? <Listings /> : <Navigate to="/" />} 
        />
        <Route 
          path="/productions" 
          element={isAuthenticated ? <Productions /> : <Navigate to="/" />} 
        />
        <Route 
          path="/productions/:id/listings" 
          element={isAuthenticated ? <Listings /> : <Navigate to="/" />} 
        />
        <Route 
          path="/listings/:id" 
          element={isAuthenticated ? <ListingDetailInternal /> : <Navigate to="/" />} 
        />
      </Routes>
    </Router>
  );
};

const App: React.FC = () => {
  return (
    <Authenticator>
      <AuthenticatedApp />
    </Authenticator>
  );
};

export default App;