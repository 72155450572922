import { useState } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from "./components/ui/Card"
import { Badge } from "./components/ui/Badge"
import { Button } from "./components/ui/Button"
import { Input } from "./components/ui/Input"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./components/ui/Table"
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetFooter } from "./components/ui/Sheet"
import { Separator } from "./components/ui/Separator"
import { FileText, MoreVertical, Search, X } from "lucide-react"
import CandidateDrawer from './CandidateDrawer'
import { AddCandidateModal } from './AddCandidate'

interface Experience {
  productionName: string;
  startYear: string;
  role: string;
  showReel: string;
}

interface Candidate {
  id: number;
  name: string;
  location: string;
  jobTitle: string;
  email: string;
  rate: string;
  status: string;
  phone: string;
  experience: string;
  skills: string[];
  availability: string;
  notes: string;
  experiences: Experience[];
  cv: string;
  dayRate: string;
}

export default function CandidatesPage() {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedCandidate, setSelectedCandidate] = useState<Candidate | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Enhanced mock data with experiences array
  const candidates: Candidate[] = [
    {
      id: 1,
      name: "John Doe",
      location: "Toronto, ON",
      jobTitle: "Camera Operator",
      email: "john@example.com",
      rate: "$500/day",
      status: "Applied",
      phone: "+1 (555) 123-4567",
      experience: "5 years",
      skills: ["4K filming", "Drone operation", "Underwater photography"],
      availability: "Immediate",
      notes: "Worked on 3 seasons of Arctic Explorers",
      dayRate: "$500",
      cv: "john_doe_cv.pdf",
      experiences: [
        {
          productionName: "Arctic Explorers",
          startYear: "2022",
          role: "Camera Operator",
          showReel: "https://example.com/reel1"
        },
        {
          productionName: "Mountain Survivors",
          startYear: "2021",
          role: "Junior Camera Operator",
          showReel: "https://example.com/reel2"
        }
      ]
    },
    // ... other candidates with similar detailed structure
  ]

  const getStatusColor = (status: string) => {
    switch (status) {
      case "Applied": return "bg-blue-100 text-blue-800"
      case "Reviewed": return "bg-yellow-100 text-yellow-800"
      case "Shortlisted": return "bg-green-100 text-green-800"
      case "Rejected": return "bg-red-100 text-red-800"
      default: return "bg-gray-100 text-gray-800"
    }
  }

  const filteredCandidates = candidates.filter(candidate =>
    candidate.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    candidate.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
    candidate.rate.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const openCandidateDrawer = (candidate: Candidate) => {
    setSelectedCandidate(candidate)
    setDrawerOpen(true)
  }

  return (
    <div className="py-8">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
          <CardTitle>Candidates</CardTitle>
          <AddCandidateModal />
        </CardHeader>
        <CardContent>
          <div className="mb-4 flex items-center space-x-2">
            <Search className="text-gray-400" />
            <Input
              placeholder="Search by name, location, or rate"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="flex-grow"
            />
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Location</TableHead>
                <TableHead>Job Title</TableHead>
                <TableHead>Email</TableHead>
                <TableHead>Rate</TableHead>
                <TableHead>CV</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredCandidates.map((candidate) => (
                <TableRow key={candidate.id} className="cursor-pointer hover:bg-gray-100" onClick={() => openCandidateDrawer(candidate)}>
                  <TableCell className="font-medium">{candidate.name}</TableCell>
                  <TableCell>{candidate.location}</TableCell>
                  <TableCell>{candidate.jobTitle}</TableCell>
                  <TableCell>{candidate.email}</TableCell>
                  <TableCell>{candidate.rate}</TableCell>
                  <TableCell>
                    <Button variant="ghost" size="icon">
                      <FileText className="h-4 w-4" />
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Badge className={getStatusColor(candidate.status)}>{candidate.status}</Badge>
                  </TableCell>
                  <TableCell>
                    <Button variant="ghost" size="icon">
                      <MoreVertical className="h-4 w-4" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      {selectedCandidate && (
        <CandidateDrawer/>
      )}
    </div>
  )
}