'use client'

import { useState } from 'react'
import { Search, MoreVertical } from 'lucide-react'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'

import { Input } from "./components/ui/Input"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./components/ui/Table"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "./components/ui/Card"
import { Badge } from "./components/ui/Badge"
import { Button } from "./components/ui/Button"
import CreateJob from './Add' // Import the CreateJob component
import { Job, Production } from './Job'

// Define the Job interface to share between components
// interface Job {
//   id: number;
//   role: string;
//   production: string;
//   startDate: Date;
//   endDate: Date;
//   approximateDates: boolean;
//   status: string;
//   budget: string;
//   budgetRate: string;
//   location: string;
//   description: string;
// }

// Mock data for jobs
const initialProductions: Production[] = [
  { 
    id: 1, 
    name: 'A new production',
    startDate: new Date('2024-03-01'), 
    endDate: new Date('2024-06-30'), 
    description: 'A production description.',
  },
  // ... other initial jobs
]

export default function JobsPage() {
  const navigate = useNavigate()
  const [jobs, setJobs] = useState<Production[]>(initialProductions)
  const [searchTerm, setSearchTerm] = useState('')

  const filteredJobs = jobs.filter(job => 
    Object.values(job).some(value => 
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  )

  const handleRowClick = (jobId: number) => {
    navigate(`/productions/${jobId}`)
  }

  const handleJobCreated = (newJob: Production) => {
    // Format the job data to match the expected structure
    const formattedJob = {
      ...newJob,
      // role: newJob.job, // Map 'job' field to 'role' to match existing structure
      startDate: newJob.startDate || new Date(),
      endDate: newJob.endDate || new Date(),
    }
    setJobs(prevJobs => [...prevJobs, formattedJob])
  }

  return (
    <div className="container mx-auto py-10">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
          <CardTitle className="text-2xl font-bold">Productions</CardTitle>
          {/* <CreateJob onJobCreated={handleJobCreated} /> */}
        </CardHeader>
        <CardContent>
          <div className="flex items-center py-4">
            <Input
              placeholder="Search productions..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="max-w-sm"
            />
            <Search className="ml-2 h-4 w-4 text-muted-foreground" />
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[200px] whitespace-nowrap">Name</TableHead>
                {/* <TableHead>Production</TableHead> */}
                {/* <TableHead>Location</TableHead>
                <TableHead>Dates</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Budget</TableHead> */}
                <TableHead>Start Date</TableHead>
                <TableHead>End Date</TableHead>
                <TableHead>Action</TableHead>
                <TableHead className="w-[50px]"></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredJobs.map((job) => (
                <TableRow 
                  key={job.id}
                  className="cursor-pointer hover:bg-accent"
                  onClick={() => handleRowClick(job.id)}
                >
                  <TableCell className="font-medium whitespace-nowrap overflow-hidden text-ellipsis max-w-[200px]">
                    {job.name}
                  </TableCell>
                  <TableCell>01/01/2024</TableCell>
                  <TableCell>01/01/2024</TableCell>
                  {/* <TableCell>{job.production}</TableCell>
                  <TableCell>{job.location}</TableCell> */}
                  <TableCell>
                    {/* {format(job.startDate, 'dd-MM-yy')} to {format(job.endDate, 'dd-MM-yy')} */}
                  </TableCell>
                  {/* <TableCell>
                    <Badge variant={job.status === 'Open' ? 'default' : 'secondary'}>
                      {job.status}
                    </Badge>
                  </TableCell> */}
                  {/* <TableCell>{`$${job.budget} ${job.budgetRate}`}</TableCell> */}
                  <TableCell>
                    <Button variant="ghost" size="icon">
                      <MoreVertical className="h-4 w-4" />
                      <span className="sr-only">Open menu</span>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  )
}