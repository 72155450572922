import { CalendarIcon, MapPinIcon, BriefcaseIcon, PhoneIcon, MailIcon, UserIcon } from 'lucide-react'
import { format } from "date-fns"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./components/ui/Card"
import { Badge } from "./components/ui/Badge"
import PageHeader from './PageHeader';
// import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"

interface Job {
  id: number;
  production: string;
  role: string;
  startDate: Date | undefined;
  endDate: Date | undefined;
  approximateDates: boolean;
  location: string;
  budget: string;
  budgetRate: string;
  description: string;
  status: 'Filled' | 'Hiring';
  name?: string;
  email?: string;
  phoneNumber?: string;
}

export default function CrewDetails({ job }: { job: Job | undefined }) {
  if (!job) {
    return (
      <Card className="w-full max-w-3xl mx-auto mt-6">
        <CardContent className="pt-6">
          <p className="text-center text-muted-foreground">No crew details available.</p>
        </CardContent>
      </Card>
    )
  }

  return (
    <>
    <Card className="">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <div className="flex flex-col space-y-1">
          <CardTitle className="text-2xl font-bold">{job.role}</CardTitle>
          <CardDescription className="text-base text-muted-foreground">{job.production}</CardDescription>
        </div>
        {/* <Avatar className="h-16 w-16">
          <AvatarImage src={`https://avatar.vercel.sh/${job.id}.png`} alt={job.name || job.role} />
          <AvatarFallback>{job.name ? job.name.charAt(0).toUpperCase() : "C"}</AvatarFallback>
        </Avatar> */}
      </CardHeader>
      <CardContent>
        <div className="grid gap-4">
          <div className="flex items-center space-x-4 text-sm">
            <Badge variant={job.status === 'Hiring' ? "default" : "secondary"}>{job.status}</Badge>
            <div className="flex items-center text-muted-foreground">
              <MapPinIcon className="mr-1 h-3 w-3" />
              {job.location}
            </div>
            <div className="flex items-center text-muted-foreground">
              <BriefcaseIcon className="mr-1 h-3 w-3" />
              {job.budget} {job.budgetRate}
            </div>
          </div>

          <div className="border-t border-b py-4">
            <h3 className="font-semibold mb-2">Crew Member Details</h3>
            <div className="grid gap-2">
              <div className="flex items-center space-x-2 text-sm">
                <UserIcon className="h-4 w-4 text-muted-foreground" />
                <span>{job.status === 'Filled' ? job.name : '-'}</span>
              </div>
              <div className="flex items-center space-x-2 text-sm">
                <MailIcon className="h-4 w-4 text-muted-foreground" />
                {job.status === 'Filled' && job.email ? (
                  <a href={`mailto:${job.email}`} className="text-blue-500 hover:underline">{job.email}</a>
                ) : (
                  <span>-</span>
                )}
              </div>
              <div className="flex items-center space-x-2 text-sm">
                <PhoneIcon className="h-4 w-4 text-muted-foreground" />
                {job.status === 'Filled' && job.phoneNumber ? (
                  <a href={`tel:${job.phoneNumber}`} className="text-blue-500 hover:underline">{job.phoneNumber}</a>
                ) : (
                  <span>-</span>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center space-x-2 text-sm text-muted-foreground">
            <CalendarIcon className="h-4 w-4" />
            <span>
              {job.startDate && job.endDate
                ? `${format(job.startDate, "MMM d, yyyy")} - ${format(job.endDate, "MMM d, yyyy")}`
                : job.startDate
                ? `Starts ${format(job.startDate, "MMM d, yyyy")}`
                : `Ends ${format(job.endDate!, "MMM d, yyyy")}`}
              {job.approximateDates && " (Approximate)"}
            </span>
          </div>

          <p className="text-sm text-muted-foreground">{job.description}</p>
        </div>
      </CardContent>
    </Card>
    </>
  )
}