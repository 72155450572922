"use client"

import { useState } from "react"
import { X, Upload, Edit } from 'lucide-react'
import { Button } from "./components/ui/Button"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogTrigger,
} from "./components/ui/Dialog"
import { Input } from "./components/ui/Input"
import { Label } from "./components/ui/Label"
import { Textarea } from "./components/ui/Textarea"
import { Badge } from "./components/ui/Badge"


interface Candidate {
  name: string
  jobTitle: string
  email: string
  phone: string
  location: string
  rate: string
  status: string
  notes: string
  cv?: File
}

export function AddCandidateModal() {
  const [isOpen, setIsOpen] = useState(false)
  const [candidate, setCandidate] = useState<Candidate>({
    name: "",
    jobTitle: "",
    email: "",
    phone: "",
    location: "",
    rate: "",
    status: "Shortlisted",
    notes: "",
  })
  const [errors, setErrors] = useState<Partial<Record<keyof Candidate, string>>>({})

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setCandidate({ ...candidate, [name]: value })
    if (errors[name as keyof Candidate]) {
      setErrors({ ...errors, [name]: undefined })
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setCandidate({ ...candidate, cv: file })
    }
  }

  const validateForm = (): boolean => {
    const newErrors: Partial<Record<keyof Candidate, string>> = {}
    if (!candidate.name) newErrors.name = "Name is required"
    if (!candidate.email) newErrors.email = "Email is required"
    if (!candidate.phone) newErrors.phone = "Phone number is required"
    
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = () => {
    if (validateForm()) {
      console.log("Submitting candidate:", candidate)
      setIsOpen(false)
      setCandidate({
        name: "",
        jobTitle: "",
        email: "",
        phone: "",
        location: "",
        rate: "",
        status: "Shortlisted",
        notes: "",
      })
      setErrors({})
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Add Candidate</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <div className="flex items-start justify-between">
            <div className="space-y-2">
              <DialogTitle>Add New Candidate</DialogTitle>
              <DialogDescription>Enter the details of the new candidate here.</DialogDescription>
            </div>
            <Button
              variant="ghost"
              size="icon"
              className="rounded-full"
              onClick={() => setIsOpen(false)}
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        </DialogHeader>

        <div className="space-y-6 py-4">
          {/* Basic Information */}
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="name">Name*</Label>
                <Input
                  id="name"
                  name="name"
                  value={candidate.name}
                  onChange={handleInputChange}
                  className={errors.name ? "border-red-500" : ""}
                />
                {errors.name && <p className="text-sm text-red-500">{errors.name}</p>}
              </div>
              <div className="space-y-2">
                <Label htmlFor="jobTitle">Job Title</Label>
                <Input
                  id="jobTitle"
                  name="jobTitle"
                  value={candidate.jobTitle}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div>
              <Label>Status</Label>
              <div className="mt-2">
                <Badge variant="secondary" className="bg-yellow-100 text-yellow-800">
                  Shortlisted
                </Badge>
              </div>
            </div>
          </div>

          {/* Contact Details */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold flex items-center gap-2">
              Contact Details
              <Edit className="h-4 w-4" />
            </h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="email">Email*</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={candidate.email}
                  onChange={handleInputChange}
                  className={errors.email ? "border-red-500" : ""}
                />
                {errors.email && <p className="text-sm text-red-500">{errors.email}</p>}
              </div>
              <div className="space-y-2">
                <Label htmlFor="phone">Phone*</Label>
                <Input
                  id="phone"
                  name="phone"
                  type="tel"
                  value={candidate.phone}
                  onChange={handleInputChange}
                  className={errors.phone ? "border-red-500" : ""}
                />
                {errors.phone && <p className="text-sm text-red-500">{errors.phone}</p>}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="location">Location</Label>
                <Input
                  id="location"
                  name="location"
                  value={candidate.location}
                  onChange={handleInputChange}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="rate">Day Rate</Label>
                <Input
                  id="rate"
                  name="rate"
                  value={candidate.rate}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          {/* Notes */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold flex items-center gap-2">
              Notes
              <Edit className="h-4 w-4" />
            </h3>
            <Textarea
              id="notes"
              name="notes"
              value={candidate.notes}
              onChange={handleInputChange}
              className="min-h-[100px]"
            />
          </div>

          {/* CV Upload */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">CV</h3>
            <div className="flex items-center gap-4">
              <Input
                id="cv"
                type="file"
                onChange={handleFileChange}
                className="hidden"
                accept=".pdf,.doc,.docx"
              />
              <Label
                htmlFor="cv"
                className="flex items-center gap-2 px-4 py-2 border rounded-md cursor-pointer hover:bg-gray-50"
              >
                <Upload className="h-4 w-4" />
                Upload CV
              </Label>
              {candidate.cv && <span className="text-sm text-gray-500">{candidate.cv.name}</span>}
            </div>
          </div>

          <Button onClick={handleSubmit} className="w-full">
            Add Candidate
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
