'use client'

import { useState } from 'react'
import { Button } from "./components/ui/Button"
import { Sheet, SheetContent, SheetFooter } from "./components/ui/Sheet"
import { Separator } from "./components/ui/Separator"
import { Badge } from "./components/ui/Badge"
import { FileText, Edit, Check } from 'lucide-react'
import { Input } from "./components/ui/Input"
import { Textarea } from "./components/ui/Textarea"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./components/ui/Select"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "./components/ui/Dialog"
import { Label } from "./components/ui/Label"

// Mock candidate data
const initialCandidate = {
  name: "John Doe",
  jobTitle: "Camera Operator",
  email: "john@example.com",
  phone: "+1 (555) 123-4567",
  location: "Toronto, ON",
  dayRate: "$500",
  status: "Shortlisted",
  notes: "John is an experienced camera operator with a keen eye for detail. He has worked in various challenging environments and has a track record of delivering high-quality footage.",
  experiences: [
    { productionName: "Arctic Explorers", startYear: "2022", role: "Camera Operator", showReel: "https://example.com/reel1" },
    { productionName: "Mountain Survivors", startYear: "2021", role: "Junior Camera Operator", showReel: "https://example.com/reel2" },
    { productionName: "Ocean Challenge", startYear: "2020", role: "Camera Assistant", showReel: "https://example.com/reel3" },
  ],
  cv: "john_doe_cv.pdf"
}

export default function Component() {
  const [open, setOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [editedCandidate, setEditedCandidate] = useState(initialCandidate)
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false)
  const [tempStatus, setTempStatus] = useState(editedCandidate.status)
  const [isContactModalOpen, setIsContactModalOpen] = useState(false)
  const [tempContact, setTempContact] = useState({ email: editedCandidate.email, phone: editedCandidate.phone })

  const getStatusColor = (status: string) => {
    switch (status) {
      case "Shortlisted": return "bg-yellow-100 text-yellow-800"
      case "Applied": return "bg-blue-100 text-blue-800"
      case "On Hold": return "bg-gray-100 text-gray-800"
      case "Confirmed": return "bg-green-100 text-green-800"
      default: return "bg-gray-100 text-gray-800"
    }
  }

  const handleEdit = () => {
    setIsEditing(!isEditing)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setEditedCandidate(prev => ({ ...prev, [name]: value }))
  }

  const handleStatusChange = (value: string) => {
    setTempStatus(value)
  }

  const saveStatus = () => {
    setEditedCandidate(prev => ({ ...prev, status: tempStatus }))
    setIsStatusModalOpen(false)
  }

  const handleContactChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setTempContact(prev => ({ ...prev, [name]: value }))
  }

  const saveContactDetails = () => {
    setEditedCandidate(prev => ({ ...prev, email: tempContact.email, phone: tempContact.phone }))
    setIsContactModalOpen(false)
  }

  return (
    <div className="p-4">
      <Button onClick={() => setOpen(true)}>
        View Candidate
      </Button>

      <Sheet open={open} onOpenChange={setOpen}>
        <SheetContent className="w-[90vw] max-w-[500px] sm:max-w-[600px] overflow-y-auto p-4 sm:p-6" side="right">
          <div className="border-b pb-4 mb-6">
            <h2 className="text-2xl font-semibold">{editedCandidate.name}</h2>
            <p className="text-lg text-muted-foreground">{editedCandidate.jobTitle}</p>
            <div className="mt-2 flex items-center space-x-2">
              <Badge className={getStatusColor(editedCandidate.status)}>
                {editedCandidate.status}
              </Badge>
              <Button variant="ghost" size="icon" onClick={() => setIsStatusModalOpen(true)} aria-label="Edit status">
                <Edit className="h-4 w-4" />
              </Button>
            </div>
          </div>

          <div className="space-y-6">
            <div>
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">Contact Details</h2>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => {
                    setTempContact({ email: editedCandidate.email, phone: editedCandidate.phone })
                    setIsContactModalOpen(true)
                  }}
                  aria-label="Edit contact details"
                >
                  <Edit className="h-4 w-4" />
                </Button>
              </div>
              <div className="grid grid-cols-2 gap-x-6 gap-y-4">
                <div>
                  <p className="text-sm text-muted-foreground mb-0.5">Email</p>
                  <p className="text-base">{editedCandidate.email}</p>
                </div>
                <div>
                  <p className="text-sm text-muted-foreground mb-0.5">Phone</p>
                  <p className="text-base">{editedCandidate.phone}</p>
                </div>
                <div>
                  <p className="text-sm text-muted-foreground mb-0.5">Location</p>
                  <p className="text-base">{editedCandidate.location}</p>
                </div>
                <div>
                  <p className="text-sm text-muted-foreground mb-0.5">Day Rate</p>
                  <p className="text-base">{editedCandidate.dayRate}</p>
                </div>
              </div>
            </div>

            <div>
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">Notes</h2>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => setIsEditingNotes(!isEditingNotes)}
                  aria-label={isEditingNotes ? "Save notes" : "Edit notes"}
                >
                  {isEditingNotes ? <Check className="h-4 w-4" /> : <Edit className="h-4 w-4" />}
                </Button>
              </div>
              {isEditingNotes ? (
                <Textarea
                  name="notes"
                  value={editedCandidate.notes}
                  onChange={handleInputChange}
                  className="min-h-[100px]"
                />
              ) : (
                <p className="text-base">{editedCandidate.notes}</p>
              )}
            </div>

            <div>
              <h2 className="text-lg font-semibold mb-4">Recent Experience</h2>
              <div className="space-y-6">
                {editedCandidate.experiences.map((exp, index) => (
                  <div key={index}>
                    <div className="grid grid-cols-2 gap-x-6 gap-y-3">
                      <div>
                        <p className="text-sm text-muted-foreground mb-0.5">Production Name</p>
                        <p className="text-base">{exp.productionName}</p>
                      </div>
                      <div>
                        <p className="text-sm text-muted-foreground mb-0.5">Year</p>
                        <p className="text-base">{exp.startYear}</p>
                      </div>
                      <div>
                        <p className="text-sm text-muted-foreground mb-0.5">Role</p>
                        <p className="text-base">{exp.role}</p>
                      </div>
                      <div>
                        <p className="text-sm text-muted-foreground mb-0.5">Show Reel</p>
                        <a 
                          href={exp.showReel} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className="text-base text-blue-500 hover:underline"
                        >
                          View Reel
                        </a>
                      </div>
                    </div>
                    {index < editedCandidate.experiences.length - 1 && (
                      <Separator className="my-6" />
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="flex items-center justify-between pt-4 border-t">
              <div>
                <p className="text-sm text-muted-foreground mb-0.5">CV</p>
                <p className="text-base">{editedCandidate.cv}</p>
              </div>
              <Button variant="outline" size="sm">
                <FileText className="mr-2 h-4 w-4" />
                View CV
              </Button>
            </div>
          </div>

          <SheetFooter className="border-t pt-6">
            <Button onClick={() => setOpen(false)}>Close</Button>
          </SheetFooter>
        </SheetContent>
      </Sheet>

      <Dialog open={isStatusModalOpen} onOpenChange={setIsStatusModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Update Candidate Status</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <Select value={tempStatus} onValueChange={handleStatusChange}>
              <SelectTrigger>
                <SelectValue placeholder="Select status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Shortlisted">Shortlisted</SelectItem>
                <SelectItem value="Applied">Applied</SelectItem>
                <SelectItem value="On Hold">On Hold</SelectItem>
                <SelectItem value="Confirmed">Confirmed</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsStatusModalOpen(false)}>Cancel</Button>
            <Button onClick={saveStatus}>Save</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={isContactModalOpen} onOpenChange={setIsContactModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Contact Details</DialogTitle>
          </DialogHeader>
          <div className="py-4 space-y-4">
            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                name="email"
                value={tempContact.email}
                onChange={handleContactChange}
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="phone">Phone</Label>
              <Input
                id="phone"
                name="phone"
                value={tempContact.phone}
                onChange={handleContactChange}
              />
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsContactModalOpen(false)}>Cancel</Button>
            <Button onClick={saveContactDetails}>Save</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}