import { Button } from "./components/ui/Button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "./components/ui/DropdownMenu"
import { ChevronRight, ChevronDown, ArrowLeft, Edit, Plus, Send, Archive, Trash, Link } from 'lucide-react'
import { useState } from 'react'
// import Link from 'next/link'

interface PageHeaderProps {
  title: string
  breadcrumbs?: string[]
  backUrl?: string
}

export default function PageHeader({ title, breadcrumbs = ["Home"], backUrl }: PageHeaderProps) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="bg-background border-b">
      <div className="container mx-auto px-4 py-4">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
          <div className="flex flex-col space-y-2">
            <div className="flex items-center space-x-4">
              {backUrl && (
                <Link href={backUrl}>
                  <Button variant="ghost" size="sm" className="p-0">
                    <ArrowLeft className="h-4 w-4 mr-2" />
                    <span>Back</span>
                  </Button>
                </Link>
              )}
              <div className="flex items-center text-sm text-muted-foreground">
                {breadcrumbs.map((crumb, index) => (
                  <div key={index} className="flex items-center">
                    {index > 0 && <ChevronRight className="h-4 w-4 mx-1" />}
                    <span>{crumb}</span>
                  </div>
                ))}
              </div>
            </div>
            <h1 className="text-2xl font-bold">{title}</h1>
          </div>
          <div className="flex items-center space-x-2">
            <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
              <DropdownMenuTrigger asChild>
                <Button className="flex items-center">
                  Options
                  <ChevronDown className="ml-2 h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem>
                  <Edit className="mr-2 h-4 w-4" />
                  <span>Edit</span>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Plus className="mr-2 h-4 w-4" />
                  <span>Create job ad</span>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Send className="mr-2 h-4 w-4" />
                  <span>Send to Contacts</span>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Archive className="mr-2 h-4 w-4" />
                  <span>Archive</span>
                </DropdownMenuItem>
                <DropdownMenuItem className="text-red-600">
                  <Trash className="mr-2 h-4 w-4" />
                  <span>Delete</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
    </div>
  )
}